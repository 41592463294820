import React from "react";
import { Link } from "react-router-dom";

import {
  Container,
  Main,
  BannerImg,
  ProPlan,
  ProPlanTitle,
  ProPlanSubtitle,
  ProPlanButton,
  ProPlanButtonTitle,
  Games,
  GroupGames,
  GamesTitle,
  GamesTitleIcon,
  DownloadApps,
  Apps,
  App,
} from "./styles";

import { RiMedalLine } from "react-icons/ri";
import { BiJoystick } from "react-icons/bi";
import { HiDownload } from "react-icons/hi";
import { AiFillApple, AiFillAndroid } from "react-icons/ai";

import Navbar from "../../components/Navbar";
import GamesLink from "../../components/Games";
import Menu from "../../components/Menu";


import Banner from "../../assets/BANNERNOVO.webp";


export default function Home() {
  return (
    <Container>
      <Navbar title="Instalação" />
      <Main>
       <Link to="https://go.apostaganha.bet/visit/?bta=71579&brand=apostaganha&utm_campaign=camillinhar">
          <BannerImg src={Banner} alt="Banner Semana Maluca" />
        </Link>

        <DownloadApps>
          <GamesTitle>
            <GamesTitleIcon>
              <HiDownload color="#fff" size={24} />
            </GamesTitleIcon>
            Instalação do App
          </GamesTitle>

          <Apps>
            <Link to="/Page_Ios">
              <App>
                <AiFillApple color="#fff" size={20} />
                IOS
              </App>
            </Link>
            <Link to="/Page_Android">
              <App>
                <AiFillAndroid color="#fff" size={20} />
                Android
              </App>
            </Link>
          </Apps>
        </DownloadApps>

      </Main>
      <Menu active="baixar" />
    </Container>

  );
}
